module.exports = new Promise((resolve, reject) => {
		const hostname = window.location.hostname;
		const envEntry = Object.entries({"local":["localhost"],"dev":["admin-console-client-wlwij2koxa-ue.a.run.app","console.dev.rotw.uplight.io"],"stg":["admin-console-client-dfuy5txq2a-ue.a.run.app","console.stg.rotw.uplight.io"],"uat":["admin-console-client-5z4yognwxq-ue.a.run.app","console.uat.rotw.uplight.io"],"prd":["admin-console-client-hkq4xbkz6q-ue.a.run.app","console.prd.rotw.uplight.io"]}).find(([_, hosts]) =>
			hosts.some((host) => hostname.includes(host))
		);
		const env = envEntry?.[0] || 'prd';
		const appLink = {"config_ui":{"dev":"https://admin-console-ui-config-wlwij2koxa-ue.a.run.app","stg":"https://admin-console-ui-config-dfuy5txq2a-ue.a.run.app","uat":"https://admin-console-ui-config-5z4yognwxq-ue.a.run.app","prd":"https://admin-console-ui-config-hkq4xbkz6q-ue.a.run.app"},"tenant_admin":{"dev":"https://ac-ui-tenant-admin-wlwij2koxa-ue.a.run.app","stg":"https://ac-ui-tenant-admin-dfuy5txq2a-ue.a.run.app","uat":"https://ac-ui-tenant-admin-5z4yognwxq-ue.a.run.app","prd":"https://ac-ui-tenant-admin-hkq4xbkz6q-ue.a.run.app"},"users_permissions":{"dev":"https://ac-ui-users-permissions-wlwij2koxa-ue.a.run.app","stg":"https://ac-ui-users-permissions-dfuy5txq2a-ue.a.run.app","uat":"https://ac-ui-users-permissions-5z4yognwxq-ue.a.run.app","prd":"https://ac-ui-users-permissions-hkq4xbkz6q-ue.a.run.app"},"program_insights":{"dev":"https://ac-ui-program-insights-wlwij2koxa-ue.a.run.app","stg":"https://ac-ui-program-insights-dfuy5txq2a-ue.a.run.app","uat":"https://ac-ui-program-insights-5z4yognwxq-ue.a.run.app","prd":"https://ac-ui-program-insights-hkq4xbkz6q-ue.a.run.app"},"csr_suite":{"dev":"https://ac-ui-csr-suite-wlwij2koxa-ue.a.run.app","stg":"https://ac-ui-csr-suite-dfuy5txq2a-ue.a.run.app","uat":"https://ac-ui-csr-suite-5z4yognwxq-ue.a.run.app","prd":"https://ac-ui-csr-suite-hkq4xbkz6q-ue.a.run.app"},"comms_history":{"dev":"https://ac-ui-comms-history-wlwij2koxa-ue.a.run.app","stg":"https://ac-ui-comms-history-dfuy5txq2a-ue.a.run.app","uat":"https://ac-ui-comms-history-5z4yognwxq-ue.a.run.app","prd":"https://ac-ui-comms-history-hkq4xbkz6q-ue.a.run.app"}}['csr_suite'][env];

		const remoteUrl = env === 'local' 
			? `http://${hostname}:3005/csr_suiteRemoteEntry.js` 
			: `${appLink}/csr_suiteRemoteEntry.js`;

		const script = document.createElement('script')
		script.src = remoteUrl

		script.onerror = () => {
			console.error('Failed to load remote script: ', remoteUrl);
			reject(new Error('csr_suite is currently unavailable. Please try again later.'));
		};

		script.onload = () => {
			const proxy = {
				get: (request) => window.csr_suite.get(request),
				init: (arg) => {
					try {
						return window.csr_suite.init(arg)
					} catch(e) {
						console.log('csr_suite remote container already initialized')
					}
				}
			}
			resolve(proxy)
		}
		document.head.appendChild(script);
    });